export const getOrderStatusLabel = (status: string) => {
    switch (status) {
        case 'AWAITING_ADDRESS':
            return 'Aguardando Endereço de Entrega'
        case 'RESEND':
            return 'Reenviar'
        case 'PENDING':
            return 'Processando'
        case 'SENT':
            return 'Enviado'
        case 'DELIVERED':
            return 'Entregue'
        case 'CANCELED':
            return 'Cancelado'
        case 'RETURNED':
            return 'Devolvido'
        case 'INCORRECT_ADDRESS':
            return 'Endereço Incorreto/Incompleto'
        default:
            return ''
    }
}

export const getOrderStatusColor = (status: string) => {
    switch (status) {
        case 'AWAITING_ADDRESS':
            return 'warning'
        case 'RESEND':
            return 'warning'
        case 'PENDING':
            return 'info'
        case 'SENT':
            return 'success'
        case 'DELIVERED':
            return 'success'
        case 'CANCELED':
            return 'error'
        case 'RETURNED':
            return 'error'
        case 'INCORRECT_ADDRESS':
            return 'error'
        default:
            return undefined
    }
}

export const STATUS_OPTIONS = [
    { value: 'AWAITING_ADDRESS', label: getOrderStatusLabel('AWAITING_ADDRESS') },
    { value: 'PENDING', label: getOrderStatusLabel('PENDING') },
    { value: 'SENT', label: getOrderStatusLabel('SENT') },
    { value: 'DELIVERED', label: getOrderStatusLabel('DELIVERED') },
    { value: 'CANCELED', label: getOrderStatusLabel('CANCELED') },
    { value: 'RETURNED', label: getOrderStatusLabel('RETURNED') },
    { value: 'INCORRECT_ADDRESS', label: getOrderStatusLabel('INCORRECT_ADDRESS') },
    { value: 'RESEND', label: getOrderStatusLabel('RESEND') },
]

export const formatCEP = (value: string) => {
    return value.replace(/\D/g,'').replace(/(\d{5})(\d{3})/,'$1-$2')
}

export const COUNTRY_OPTIONS = [
    {
      "value": "AO",
      "label": "Angola"
    },
    {
      "value": "KI",
      "label": "Kiribati"
    },
    {
      "value": "GG",
      "label": "Guernsey"
    },
    {
      "value": "GP",
      "label": "Guadalupe"
    },
    {
      "value": "KM",
      "label": "Comores"
    },
    {
      "value": "TC",
      "label": "Ilhas Turks e Caicos"
    },
    {
      "value": "KY",
      "label": "Ilhas Caimão"
    },
    {
      "value": "IL",
      "label": "Israel"
    },
    {
      "value": "SN",
      "label": "Senegal"
    },
    {
      "value": "SS",
      "label": "Sudão do Sul"
    },
    {
      "value": "AX",
      "label": "Alândia"
    },
    {
      "value": "NZ",
      "label": "Nova Zelândia"
    },
    {
      "value": "PS",
      "label": "Palestina"
    },
    {
      "value": "LR",
      "label": "Libéria"
    },
    {
      "value": "SB",
      "label": "Ilhas Salomão"
    },
    {
      "value": "SI",
      "label": "Eslovénia"
    },
    {
      "value": "TK",
      "label": "Tokelau"
    },
    {
      "value": "UZ",
      "label": "Uzbequistão"
    },
    {
      "value": "ZW",
      "label": "Zimbabwe"
    },
    {
      "value": "TG",
      "label": "Togo"
    },
    {
      "value": "CF",
      "label": "República Centro-Africana"
    },
    {
      "value": "AE",
      "label": "Emirados Árabes Unidos"
    },
    {
      "value": "ZM",
      "label": "Zâmbia"
    },
    {
      "value": "KH",
      "label": "Camboja"
    },
    {
      "value": "VN",
      "label": "Vietname"
    },
    {
      "value": "PN",
      "label": "Ilhas Pitcairn"
    },
    {
      "value": "TF",
      "label": "Terras Austrais e Antárticas Francesas"
    },
    {
      "value": "PF",
      "label": "Polinésia Francesa"
    },
    {
      "value": "ZA",
      "label": "África do Sul"
    },
    {
      "value": "RE",
      "label": "Reunião"
    },
    {
      "value": "AU",
      "label": "Austrália"
    },
    {
      "value": "FI",
      "label": "Finlândia"
    },
    {
      "value": "GY",
      "label": "Guiana"
    },
    {
      "value": "MZ",
      "label": "Moçambique"
    },
    {
      "value": "TM",
      "label": "Turquemenistão"
    },
    {
      "value": "LA",
      "label": "Laos"
    },
    {
      "value": "HU",
      "label": "Hungria"
    },
    {
      "value": "VG",
      "label": "Ilhas Virgens"
    },
    {
      "value": "CG",
      "label": "Congo"
    },
    {
      "value": "AR",
      "label": "Argentina"
    },
    {
      "value": "MT",
      "label": "Malta"
    },
    {
      "value": "TR",
      "label": "Turquia"
    },
    {
      "value": "HT",
      "label": "Haiti"
    },
    {
      "value": "GQ",
      "label": "Guiné Equatorial"
    },
    {
      "value": "GR",
      "label": "Grécia"
    },
    {
      "value": "LT",
      "label": "Lituânia"
    },
    {
      "value": "SY",
      "label": "Síria"
    },
    {
      "value": "HM",
      "label": "Ilha Heard e Ilhas McDonald"
    },
    {
      "value": "SM",
      "label": "San Marino"
    },
    {
      "value": "SE",
      "label": "Suécia"
    },
    {
      "value": "BZ",
      "label": "Belize"
    },
    {
      "value": "UM",
      "label": "Ilhas Menores Distantes dos Estados Unidos"
    },
    {
      "value": "CL",
      "label": "Chile"
    },
    {
      "value": "RS",
      "label": "Sérvia"
    },
    {
      "value": "US",
      "label": "Estados Unidos"
    },
    {
      "value": "DZ",
      "label": "Argélia"
    },
    {
      "value": "BO",
      "label": "Bolívia"
    },
    {
      "value": "JE",
      "label": "Jersey"
    },
    {
      "value": "CK",
      "label": "Ilhas Cook"
    },
    {
      "value": "VU",
      "label": "Vanuatu"
    },
    {
      "value": "NA",
      "label": "Namíbia"
    },
    {
      "value": "SV",
      "label": "El Salvador"
    },
    {
      "value": "YE",
      "label": "Iémen"
    },
    {
      "value": "MO",
      "label": "Macau"
    },
    {
      "value": "MU",
      "label": "Maurício"
    },
    {
      "value": "AW",
      "label": "Aruba"
    },
    {
      "value": "PR",
      "label": "Porto Rico"
    },
    {
      "value": "KG",
      "label": "Quirguistão"
    },
    {
      "value": "GT",
      "label": "Guatemala"
    },
    {
      "value": "NC",
      "label": "Nova Caledónia"
    },
    {
      "value": "TO",
      "label": "Tonga"
    },
    {
      "value": "GN",
      "label": "Guiné"
    },
    {
      "value": "AQ",
      "label": "Antártida"
    },
    {
      "value": "TT",
      "label": "Trinidade e Tobago"
    },
    {
      "value": "JM",
      "label": "Jamaica"
    },
    {
      "value": "BS",
      "label": "Bahamas"
    },
    {
      "value": "RU",
      "label": "Rússia"
    },
    {
      "value": "LS",
      "label": "Lesoto"
    },
    {
      "value": "MM",
      "label": "Myanmar"
    },
    {
      "value": "BT",
      "label": "Butão"
    },
    {
      "value": "MR",
      "label": "Mauritânia"
    },
    {
      "value": "CN",
      "label": "China"
    },
    {
      "value": "PY",
      "label": "Paraguai"
    },
    {
      "value": "GL",
      "label": "Gronelândia"
    },
    {
      "value": "FO",
      "label": "Ilhas Faroé"
    },
    {
      "value": "SJ",
      "label": "Ilhas Svalbard e Jan Mayen"
    },
    {
      "value": "GH",
      "label": "Gana"
    },
    {
      "value": "MW",
      "label": "Malawi"
    },
    {
      "value": "PG",
      "label": "Papua Nova Guiné"
    },
    {
      "value": "FR",
      "label": "França"
    },
    {
      "value": "SZ",
      "label": "Suazilândia"
    },
    {
      "value": "JO",
      "label": "Jordânia"
    },
    {
      "value": "CA",
      "label": "Canadá"
    },
    {
      "value": "SH",
      "label": "Santa Helena, Ascensão e Tristão da Cunha"
    },
    {
      "value": "MA",
      "label": "Marrocos"
    },
    {
      "value": "MN",
      "label": "Mongólia"
    },
    {
      "value": "NR",
      "label": "Nauru"
    },
    {
      "value": "IS",
      "label": "Islândia"
    },
    {
      "value": "LU",
      "label": "Luxemburgo"
    },
    {
      "value": "TH",
      "label": "Tailândia"
    },
    {
      "value": "AD",
      "label": "Andorra"
    },
    {
      "value": "BG",
      "label": "Bulgária"
    },
    {
      "value": "NI",
      "label": "Nicarágua"
    },
    {
      "value": "IT",
      "label": "Itália"
    },
    {
      "value": "BL",
      "label": "São Bartolomeu"
    },
    {
      "value": "AS",
      "label": "Samoa Americana"
    },
    {
      "value": "CI",
      "label": "Costa do Marfim"
    },
    {
      "value": "PW",
      "label": "Palau"
    },
    {
      "value": "GU",
      "label": "Guam"
    },
    {
      "value": "GI",
      "label": "Gibraltar"
    },
    {
      "value": "AI",
      "label": "Anguilla"
    },
    {
      "value": "CO",
      "label": "Colômbia"
    },
    {
      "value": "TD",
      "label": "Chade"
    },
    {
      "value": "BR",
      "label": "Brasil"
    },
    {
      "value": "NP",
      "label": "Nepal"
    },
    {
      "value": "IM",
      "label": "Ilha de Man"
    },
    {
      "value": "GF",
      "label": "Guiana Francesa"
    },
    {
      "value": "VC",
      "label": "São Vincente e Granadinas"
    },
    {
      "value": "KP",
      "label": "Coreia do Norte"
    },
    {
      "value": "SX",
      "label": "São Martinho"
    },
    {
      "value": "SR",
      "label": "Suriname"
    },
    {
      "value": "KW",
      "label": "Kuwait"
    },
    {
      "value": "SO",
      "label": "Somália"
    },
    {
      "value": "TV",
      "label": "Tuvalu"
    },
    {
      "value": "BQ",
      "label": "Países Baixos Caribenhos"
    },
    {
      "value": "CD",
      "label": "República Democrática do Congo"
    },
    {
      "value": "AL",
      "label": "Albânia"
    },
    {
      "value": "HN",
      "label": "Honduras"
    },
    {
      "value": "EC",
      "label": "Equador"
    },
    {
      "value": "SD",
      "label": "Sudão"
    },
    {
      "value": "FM",
      "label": "Micronésia"
    },
    {
      "value": "HK",
      "label": "Hong Kong"
    },
    {
      "value": "BJ",
      "label": "Benin"
    },
    {
      "value": "EH",
      "label": "Saara Ocidental"
    },
    {
      "value": "LK",
      "label": "Sri Lanka"
    },
    {
      "value": "BD",
      "label": "Bangladesh"
    },
    {
      "value": "MG",
      "label": "Madagáscar"
    },
    {
      "value": "BH",
      "label": "Bahrein"
    },
    {
      "value": "LI",
      "label": "Liechtenstein"
    },
    {
      "value": "MK",
      "label": "Macedónia do Norte"
    },
    {
      "value": "CX",
      "label": "Ilha do Natal"
    },
    {
      "value": "UA",
      "label": "Ucrânia"
    },
    {
      "value": "EG",
      "label": "Egito"
    },
    {
      "value": "ER",
      "label": "Eritreia"
    },
    {
      "value": "MP",
      "label": "Marianas Setentrionais"
    },
    {
      "value": "AZ",
      "label": "Azerbeijão"
    },
    {
      "value": "NE",
      "label": "Níger"
    },
    {
      "value": "LC",
      "label": "Santa Lúcia"
    },
    {
      "value": "MS",
      "label": "Montserrat"
    },
    {
      "value": "VI",
      "label": "Ilhas Virgens dos Estados Unidos"
    },
    {
      "value": "SL",
      "label": "Serra Leoa"
    },
    {
      "value": "DJ",
      "label": "Djibouti"
    },
    {
      "value": "HR",
      "label": "Croácia"
    },
    {
      "value": "NO",
      "label": "Noruega"
    },
    {
      "value": "DM",
      "label": "Dominica"
    },
    {
      "value": "UY",
      "label": "Uruguai"
    },
    {
      "value": "GM",
      "label": "Gâmbia"
    },
    {
      "value": "MD",
      "label": "Moldávia"
    },
    {
      "value": "AF",
      "label": "Afeganistão"
    },
    {
      "value": "PT",
      "label": "Portugal"
    },
    {
      "value": "ME",
      "label": "Montenegro"
    },
    {
      "value": "BN",
      "label": "Brunei"
    },
    {
      "value": "FJ",
      "label": "Fiji"
    },
    {
      "value": "MX",
      "label": "México"
    },
    {
      "value": "CH",
      "label": "Suíça"
    },
    {
      "value": "RW",
      "label": "Ruanda"
    },
    {
      "value": "CY",
      "label": "Chipre"
    },
    {
      "value": "BM",
      "label": "Bermudas"
    },
    {
      "value": "TW",
      "label": "Ilha Formosa"
    },
    {
      "value": "JP",
      "label": "Japão"
    },
    {
      "value": "QA",
      "label": "Catar"
    },
    {
      "value": "DK",
      "label": "Dinamarca"
    },
    {
      "value": "CC",
      "label": "Ilhas Cocos (Keeling)"
    },
    {
      "value": "BV",
      "label": "Ilha Bouvet"
    },
    {
      "value": "BW",
      "label": "Botswana"
    },
    {
      "value": "MC",
      "label": "Mónaco"
    },
    {
      "value": "LB",
      "label": "Líbano"
    },
    {
      "value": "LY",
      "label": "Líbia"
    },
    {
      "value": "MY",
      "label": "Malásia"
    },
    {
      "value": "VA",
      "label": "Cidade do Vaticano"
    },
    {
      "value": "CR",
      "label": "Costa Rica"
    },
    {
      "value": "ES",
      "label": "Espanha"
    },
    {
      "value": "MQ",
      "label": "Martinica"
    },
    {
      "value": "SK",
      "label": "Eslováquia"
    },
    {
      "value": "BA",
      "label": "Bósnia e Herzegovina"
    },
    {
      "value": "BI",
      "label": "Burundi"
    },
    {
      "value": "CV",
      "label": "Cabo Verde"
    },
    {
      "value": "OM",
      "label": "Omã"
    },
    {
      "value": "BY",
      "label": "Bielorússia"
    },
    {
      "value": "MF",
      "label": "São Martinho"
    },
    {
      "value": "BB",
      "label": "Barbados"
    },
    {
      "value": "XK",
      "label": "Kosovo"
    },
    {
      "value": "TZ",
      "label": "Tanzânia"
    },
    {
      "value": "WS",
      "label": "Samoa"
    },
    {
      "value": "ET",
      "label": "Etiópia"
    },
    {
      "value": "IN",
      "label": "Índia"
    },
    {
      "value": "EE",
      "label": "Estónia"
    },
    {
      "value": "IE",
      "label": "Irlanda"
    },
    {
      "value": "MV",
      "label": "Maldivas"
    },
    {
      "value": "SC",
      "label": "Seicheles"
    },
    {
      "value": "UG",
      "label": "Uganda"
    },
    {
      "value": "FK",
      "label": "Ilhas Malvinas"
    },
    {
      "value": "ST",
      "label": "São Tomé e Príncipe"
    },
    {
      "value": "NU",
      "label": "Niue"
    },
    {
      "value": "TL",
      "label": "Timor-Leste"
    },
    {
      "value": "IQ",
      "label": "Iraque"
    },
    {
      "value": "KN",
      "label": "São Cristóvão e Nevis"
    },
    {
      "value": "CW",
      "label": "ilha da Curação"
    },
    {
      "value": "NF",
      "label": "Ilha Norfolk"
    },
    {
      "value": "MH",
      "label": "Ilhas Marshall"
    },
    {
      "value": "ML",
      "label": "Mali"
    },
    {
      "value": "GW",
      "label": "Guiné-Bissau"
    },
    {
      "value": "YT",
      "label": "Mayotte"
    },
    {
      "value": "PL",
      "label": "Polónia"
    },
    {
      "value": "TJ",
      "label": "Tajiquistão"
    },
    {
      "value": "GB",
      "label": "Reino Unido"
    },
    {
      "value": "KE",
      "label": "Quénia"
    },
    {
      "value": "KZ",
      "label": "Cazaquistão"
    },
    {
      "value": "PA",
      "label": "Panamá"
    },
    {
      "value": "SG",
      "label": "Singapura"
    },
    {
      "value": "NG",
      "label": "Nigéria"
    },
    {
      "value": "RO",
      "label": "Roménia"
    },
    {
      "value": "AG",
      "label": "Antígua e Barbuda"
    },
    {
      "value": "CZ",
      "label": "Chéquia"
    },
    {
      "value": "AM",
      "label": "Arménia"
    },
    {
      "value": "VE",
      "label": "Venezuela"
    },
    {
      "value": "PH",
      "label": "Filipinas"
    },
    {
      "value": "WF",
      "label": "Wallis e Futuna"
    },
    {
      "value": "DE",
      "label": "Alemanha"
    },
    {
      "value": "PE",
      "label": "Perú"
    },
    {
      "value": "CM",
      "label": "Camarões"
    },
    {
      "value": "IO",
      "label": "Território Britânico do Oceano Índico"
    },
    {
      "value": "KR",
      "label": "Coreia do Sul"
    },
    {
      "value": "PM",
      "label": "Saint-Pierre e Miquelon"
    },
    {
      "value": "GA",
      "label": "Gabão"
    },
    {
      "value": "DO",
      "label": "República Dominicana"
    },
    {
      "value": "LV",
      "label": "Letónia"
    },
    {
      "value": "IR",
      "label": "Irão"
    },
    {
      "value": "CU",
      "label": "Cuba"
    },
    {
      "value": "SA",
      "label": "Arábia Saudita"
    },
    {
      "value": "GE",
      "label": "Geórgia"
    },
    {
      "value": "PK",
      "label": "Paquistão"
    },
    {
      "value": "BE",
      "label": "Bélgica"
    },
    {
      "value": "GS",
      "label": "Ilhas Geórgia do Sul e Sandwich do Sul"
    },
    {
      "value": "TN",
      "label": "Tunísia"
    },
    {
      "value": "NL",
      "label": "Holanda"
    },
    {
      "value": "BF",
      "label": "Burkina Faso"
    },
    {
      "value": "ID",
      "label": "Indonésia"
    },
    {
      "value": "AT",
      "label": "Áustria"
    },
    {
      "value": "GD",
      "label": "Granada"
    }
  ]